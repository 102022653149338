<template>
    <div class="body" id="checklists">
        <ul class="list-group ps-5" v-if="checklist.checklistItems">
            <li class="list-group-item border-0 d-flex align-items-center hover-wrapper p-0"
                v-for="(value, key) in checklist.checklistItems" :key="key">
                <input class="form-check-input me-1 mt-0" type="checkbox" :id="key" value="" v-model="value.value" />
                <OContentEditable type="text" class="form-control-plaintext form-control-sm border-0 w-auto mx-2"
                    autocomplete="off" :id="`checklist.${index}.${key}`" v-model="value.name" style="min-width: 24px;"
                    :class="value.value ? 'text-decoration-line-through' : ''" />
                <div class="btn-group show-on-hover" role="group">
                    <button class="btn m-0 ps-3 pe-0 py-0 border-0" @click="deleteChecklistItem(key)"><i class="bi bi-x-lg"></i></button>
                </div>
            </li>

        </ul>
        <button class="btn btn-link btn-sm ps-4 me-auto" @click="addChecklistItem">
            <span class="">{{$t('Add Item')}}</span>
        </button>
    </div>
</template>

<script setup>
import o365_confirm from 'o365.controls.confirm.ts';
import { nextTick } from 'vue';

const props = defineProps({
    checklist: {
        type: Object,
        required: true
    },
    index: {
        type: Number
    }
})

async function addChecklistItem() {
    const checklistItem = {
        'name': '',
        'value': false
    };

    let index


    if (typeof props.checklist.checklistItems === 'string') {
        props.checklist.checklistItems = [checklistItem]
        index = props.checklist.checklistItems.length - 1
    } else {
        index = props.checklist.checklistItems.push(checklistItem) - 1
    }

    /* const index = props.checklist.checklistItems.push(checklistItem) - 1; */
    await nextTick()
    document.getElementById(`checklist.${props.index}.${index}`).focus()
}

function deleteChecklistItem(key) {
    const options = {
        message: $t('Are you sure you want to delete this Item?'),
        title: $t('Delete confirm'),
        btnTextOk: 'Delete',
        btnClassOk: 'btn-danger',
        zIndex: 1060
    }

    o365_confirm(options).then(() => {
        props.checklist.checklistItems.splice(key, 1)
    }).catch(_ => {})
}

window.checklist = props.checklist
</script>
